import React, {useState} from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import "./resources.css";
import Button from "react-bootstrap/Button";
import {Alert, Col, Container, Form, Row} from "react-bootstrap";
import formStyle from "./form.module.css";
import styles from "./petition.module.css";
// import addToMailchimp from "gatsby-plugin-mailchimp";
import addToMailchimp from "../services/mailchimp";
import globalStyle from "../styles/global.module.css";

const PetitionPage =  ({data}) => {

  const [formState, setFormValues] = useState({
    email: "",
    firstName: "",
    lastName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowErrorAlert] = useState({show: false, onField: ""});
  const [formResult, setFormResult] = useState({});
  const submitUrl = 'https://momsonmaternity.us17.list-manage.com/subscribe/post?u=abb88870b50fd9c89b14ecf52&amp;id=6ca3fec201';

  const resetFormAfterSubmit = () => {
    setFormValues({
      email: "",
      firstName: "",
      lastName: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
    });
  };

  const _handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = ["firstName", "lastName", "addressLine1", "city", "state", "zipCode", "email"];
    for (const field in formState) {
      if(requiredFields.indexOf(field) !== -1) {
        if(!formState[field]) {
          setShowErrorAlert({
            show: true,
            onField: field.charAt(0).toUpperCase()+ field.slice(1)
          });

          return;
        }
      }
    }

    const result = await addToMailchimp(formState.email, {
      FNAME: formState.firstName,
      LNAME: formState.lastName,
      "ADDRESS[addr1]": formState.addressLine1,
      "ADDRESS[addr2]": formState.addressLine2,
      "ADDRESS[city]": formState.city,
      "ADDRESS[state]": formState.state,
      "ADDRESS[zip]": formState.zipCode,
    }, submitUrl);
    setFormResult(result);

    setShowAlert( {show: true});

    if (result.result === 'success') {
      resetFormAfterSubmit();
    } else {
      const alreadyAMemberError = formState.email + ` is already subscribed to list`
      if (result.result === 'error' && result.msg.includes(alreadyAMemberError)) {
        setShowAlert({
          show: true,
          msg: 'You already subscribed to list!'
        });
      } else {
        setFormResult(result);
        setShowAlert({
          show: true,
          msg: result.msg
        });
      }
    }
  };

  const handleFormChange = (e) => {
    setFormValues({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Layout className="site-content">
      <Container>
      {/*<div className={styles.petitionContainer}>*/}
        <div>
          <h1 className={globalStyle.entryTitle}>I Support National Paid Family Leave</h1>
        </div>

        <div>
          <p>Each time a milestone number of new signatures is achieved, Moms on Maternity will go to the National Press. Thank you for helping get attention to this key issue of our time. On top of our press outreach, we have a “big plan” that involves all of you. You will learn about the plan via email once we hit critical mass on the petition. We can do this together. Thank you!</p>
        </div>

        <div>
          <Form onSubmit={_handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} controlId="firstName">
                <Form.Label className={[formStyle.formLabel, formStyle.required]}>First Name</Form.Label>
                <Form.Control size="sm" value={formState.firstName} name="firstName" type="text" placeholder="First name" onChange={handleFormChange} />
              </Form.Group>

              <Form.Group as={Col} controlId="lastName">
                <Form.Label className={[formStyle.formLabel, formStyle.required]}>Last Name</Form.Label>
                <Form.Control size="sm" value={formState.lastName} name="lastName" type="text" placeholder="Last name" onChange={handleFormChange} />
              </Form.Group>
            </Form.Row>

            <Form.Group controlId="formAddress">
              <Form.Label className={[formStyle.formLabel, formStyle.required]}>Address</Form.Label>
              <Form.Control size="sm" value={formState.addressLine1} type="text" name="addressLine1" placeholder="Address Line 1" onChange={handleFormChange} />
            </Form.Group>

            <Form.Group controlId="formAddress">
              <Form.Control size="sm" value={formState.addressLine2} type="text" name="addressLine2" placeholder="Address Line 2" onChange={handleFormChange} />
            </Form.Group>

            <Form.Row>
              <Form.Group as={Col} controlId="formCity">
                <Form.Control size="sm" value={formState.city} name="city" type="text" placeholder="City" onChange={handleFormChange} />
              </Form.Group>

              <Form.Group as={Col} controlId="formState">
                <Form.Control className={[styles.formControl, styles.stateSelect]} as="select" name="state" onChange={handleFormChange}>
                  <option value="">Select State</option>
                  {data.site.siteMetadata.usaStates.map(state => (
                    <option value={state.name}>{state.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Group controlId="formZipCode">
              <Form.Control size="sm" value={formState.zipCode} type="text" name="zipCode" placeholder="Zip Code" onChange={handleFormChange} />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label className={[formStyle.formLabel, formStyle.required]}>Email</Form.Label>
              <Form.Control size="sm" value={formState.email} type="email" name="email" placeholder="Your email adress" onChange={handleFormChange} />
            </Form.Group>


            <Button type="submit" className={[formStyle.btnPrimary, formStyle.submitButtonPink]}>
              Submit
            </Button>
          </Form>

          <Row>
            <Col xs={12} sm={8}>
              {
                showAlert.show && formResult.result === 'error' &&
                <Alert variant="danger" onClose={() => setShowAlert({show: false})} dismissible
                >{showAlert.msg}</Alert>
              }
              {
                showAlert.show && formResult.result === 'success' &&
                <Alert variant="primary" onClose={() => setShowAlert({show: false})} dismissible
                >We have recieved your email and will get back to you soon!</Alert>
              }
              {
                showError.show &&
                <Alert variant="danger" onClose={() => setShowErrorAlert({show: false})} dismissible
                >{showError.onField} field should not be empty</Alert>
              }
            </Col>
          </Row>
        </div>

      {/*</div>*/}
      </Container>
    </Layout>
  )
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        usaStates {
          name
          abbreviation
        }    
      }
    }
  }
`;


export default PetitionPage
